import React, { useState, useEffect, useContext } from "react";
import "./DateMatch.scss";
import {
  motion,
  useMotionValue,
  useTransform,
  useAnimation,
} from "framer-motion";
import useHideNav from "../utils/useHideNav";
import { useLocation, useNavigate } from "react-router-dom";
import UserAPI from "../api/UserAPI";
import EventsAPI from "../api/EventsAPI";

import {
  XMarkIcon,
  HeartIcon,
  ChatBubbleOvalLeftIcon,
  FlagIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import useGoBack from "../utils/useGoBack";
import { GlobalContext } from "../context/GlobalContext";
import { arrayUnion } from "firebase/firestore";
import Popup from "../components/Popup/Popup";
import ReportDialog from "../components/Popup/ReportDialog";
import { ReportUserPopup } from './DateSession/DateSessionHelpers'
import Utility from "../utils/utility";
import {
  CircleButton,
  PrimaryButton,
  SecondaryButton,
} from "../components/Buttons/buttons";


const DateMatch = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { event } = location.state;
  const [attendees, setAttendees] = useState([]);
  const [hiddenCards, setHiddenCards] = useState([]);
  const { globalState } = useContext(GlobalContext);

  useHideNav();
  const goBack = useGoBack();

  const [reportUser, setReportUser] = useState({});
  const [reportUserPopup, setReportUserPopup] = useState(false);
  const [reportUserText, setReportUserText] = useState("");
  const onCloseReportUserPopup = () => {
    setReportUserText("");
    setReportUserPopup(false);
  };

  const updateReportUserText = (e) => {
    setReportUserText(e.target.value);
  };
  const reportUserTextField = (
    <textarea
      value={reportUserText}
      onChange={updateReportUserText}
      placeholder={"Report Comment"}
      rows="3"
      className=""
    />
  );

  const onReportUserBtnPress = async (user) => {
    setReportUserPopup(true);
    setReportUser(user);
  };

  const onReportSend = async () => {
    const reportItem = {
      reportedUserID: reportUser.userID,
      reportedName: reportUser.name,
      note: reportUserText,
      reportedByUserID: globalState.user.userID,
      reportedByName: globalState.user.name,
    };
    await EventsAPI.updateEvent(event.id, {
      reports: arrayUnion(reportItem),
    });

    onCloseReportUserPopup();
  };

  useEffect(() => {
    const fetchUsers = async () => {

      const userType = Utility.getUserType(event.queue, globalState.user, event);
      const groupCount = event.groupCount;
      const oppositeType = groupCount > 1 ? Utility.getOppositeType(userType) : userType;

      const fetchedUsers = await UserAPI.fetchUsersByIds(event[oppositeType]);
      const userMap = {};
      fetchedUsers.forEach((user) => {
        userMap[user.userID] = user;
      });

      const sortedUsers = event[oppositeType]
        .map((id) => userMap[id])
        .filter(u => u != undefined || u != null)
        .reverse()
      setAttendees(sortedUsers);
    };
    if (globalState.user) {
      fetchUsers();
    }
  }, [event, globalState.user]);

  const handleLeftSwipe = (user) => {
    setHiddenCards((prev) => [...prev, user.userID]);
  };

  const handleRightSwipe = async (user) => {
    setHiddenCards((prev) => [...prev, user.userID]);
    UserAPI.addToUserLikesAndMatch(user.userID, globalState.user, event.id);
  };

  const allCardsSwiped =
    attendees.length > 0 && hiddenCards.length >= attendees.length;

  const handleNavigateToMatches = () => {
    navigate("/matches");
  };

  return (
    <div className="date-match">
      <div className="header-controls">
        {/* <button className="close" onClick={goBack}>
          <XMarkIcon />
        </button> */}
        <CircleButton onClick={goBack}>
          <XMarkIcon className="w-5 h-5" />
        </CircleButton>
      </div>
      {attendees.map(
        (user, index) =>
          !hiddenCards.includes(user.userID) && (
            <Card
              key={index}
              user={user}
              event={event}
              onLeftSwipe={() => handleLeftSwipe(user)}
              onRightSwipe={() => handleRightSwipe(user)}
              onReportUserBtnPress={onReportUserBtnPress}
            />
          )
      )}
      {allCardsSwiped && (
        <div className="p-4">
          <PrimaryButton onClick={handleNavigateToMatches} class="w-48">
            <div className="flex items-center justify-center">
              {/* <CameraIcon className="w-5 h-5 mr-2" /> */}
              <SparklesIcon className="w-5 h-5 mr-2" />
              Go to Matches
            </div>
          </PrimaryButton>
        </div>
      )}
      {reportUserPopup && (
        <ReportUserPopup
          isOpen={reportUserPopup}
          onClose={onCloseReportUserPopup}
          onConfirm={onReportSend}
          reportUserText={reportUserText}
          setReportUserText={setReportUserText}
        />
      )}
    </div>
  );
};

const Card = ({
  user,
  event,
  onLeftSwipe,
  onRightSwipe,
  onReportUserBtnPress,
}) => {
  const motionValue = useMotionValue(0);
  const rotateValue = useTransform(motionValue, [-200, 200], [-50, 50]);
  const animControls = useAnimation();
  const style = {
    backgroundImage: `url(${user.photoURL})`,
  };

  const whenReportUserPressed = () => {
    onReportUserBtnPress(user);
  };

  const handleManualSwipe = (direction) => {
    animControls.start({ x: direction === "left" ? -1000 : 1000 }).then(() => {
      if (direction === "left") {
        onLeftSwipe();
      } else {
        onRightSwipe();
      }
    });
  };

  return (
    <div className="card-container">
      <motion.div
        className="card"
        drag="x"
        style={{ x: motionValue, rotate: rotateValue, ...style }}
        dragConstraints={{ left: -1000, right: 1000 }}
        onDragEnd={(event, info) => {
          const swipeThreshold = 150; // Threshold for swipe detection
          if (Math.abs(info.offset.x) < swipeThreshold) {
            animControls.start({ x: 0 });
            motionValue.set(0);
          } else {
            animControls
              .start({ x: info.offset.x < 0 ? -1000 : 1000 })
              .then(() => {
                if (info.offset.x < 0) {
                  onLeftSwipe();
                } else {
                  onRightSwipe();
                }
              });
          }
        }}
        animate={animControls}
      >
        <div className="report-icon" onClick={(e) => whenReportUserPressed(e)}>
          <FlagIcon className="icon" /> Report
        </div>
        <div className="text-3xl font-bold text-white bg-black bg-opacity-5 p-2">
          {user.name?.split(" ")[0]}
        </div>
      </motion.div>
      <div className="button-controls ">
        <button
          className="ignore shadow-sm glass"
          onClick={() => handleManualSwipe("left")}
        >
          <XMarkIcon className="w-16 h-16" />
        </button>

        <button
          className="like shadow-sm glass"
          onClick={() => handleManualSwipe("right")}
        >
          <SparklesIcon className="w-16 h-16" />
        </button>
      </div>
    </div>
  );
};

export default DateMatch;
